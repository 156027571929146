import React from "react";
import Logo from "../img/logo.png";

const Footer = () => {
  return (
    <footer>
      <img src="https://www.liblogo.com/img-logo/me2070mbed-medium-logo-medium-icon-free-download-on-iconfinder.png" alt="" />
      <span>
        Made as <b>Meduim clone</b>.
      </span>
    </footer>
  );
};

export default Footer;
